
import { Component, Vue } from "vue-property-decorator";
import globalAxios from "axios";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

  const AppProps = Vue.extend({
  props: {
    files: {
      type: Array,
      default() {
        return []
      }
    },
    buttonText: {
      type: String,
      default: "Upload"
    },
    multiple: {
      type: Boolean,
      default: true
    }
  }
})

@Component({
  name: "FileUploader",
})
export default class extends AppProps {
  loading = false;
  currentStatus: number = STATUS_INITIAL;
  uploadedFiles: any;
  uploadError: any;
  fileCount = 0;

  get isInitial() {
    return this.currentStatus === STATUS_INITIAL;
  }
  get isSaving() {
    return this.currentStatus === STATUS_SAVING;
  }
  get isSuccess() {
    return this.currentStatus === STATUS_SUCCESS;
  }
  get isFailed() {
    return this.currentStatus === STATUS_FAILED;
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  handleAttachmentName(file: any) {
    let arr = file.split("/");
    return arr[arr.length - 1];
  }

  deleteItem(file: any) {
    let refs = this.$refs as any;
    refs.file.value = "";
    this.files.splice(this.files.indexOf(file), 1);
    this.$emit('update', this.files)
  }

  filesChange(fileList: any) {
    let files = [...fileList];
    if (!files.length) return;

    files.forEach((file: any) => {
      const formData = new FormData();
      formData.append("file", file);
      this.save(formData);
    });
  }

  async save(formData: any) {
    // upload data to the server
    this.currentStatus = STATUS_SAVING;

    await this.upload(formData)
      .then((x: any) => {
        this.files.push(x);
        this.$emit('update', this.files)
        this.currentStatus = STATUS_INITIAL;
      })
      .catch((err: any) => {
        this.uploadError = err.response;
        this.currentStatus = STATUS_FAILED;
      });
  }

  async upload(formData: any) {
    const url = `${this.uploadPath}`;
    return globalAxios
      .post(url, formData)
      .then((x: any) => x.data)
      .then((img: any) => img.relativePath);
  }
}
