
import MultipleFileUploader from "@/components/form-items/MultipleFileUploader.vue";
import FileUploader from "@/components/form-items/FileUploader.vue";
import { Component, Vue } from "vue-property-decorator";
import { filesPost } from "@/api/files"
import { paymentsManualPaymentImportPut } from "@/api/payments"

type Importer = {
  id: string,
  name: string,
  action: Function,
  files: string[],
  limitedTypes?: string[] | undefined
}

@Component({
  name: "Importers",
  components: {
    MultipleFileUploader,
    FileUploader
  },
})
export default class extends Vue {
  tableData: Importer[] = [
    {
      id: "manual-payments",
      name: "Manual Payments",
      action: this.manualPaymentsImport,
      files: [],
      limitedTypes: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    }
  ] 

  importFile(file: any, row: Importer) {
    row.files = file;
    filesPost(file[0].raw).then((res) => {
      if (res) {
        row.action(res.relativePath, row);
        row.files = [];
      }
    })
  }

  manualPaymentsImport(url:string, row: Importer) {
    row.files = [];
    paymentsManualPaymentImportPut(url)
  }
}
