import { FilePath } from "@/api-client";
import { Files } from "@/network/api";
import { handleError } from "@/utils/auth";

export function filesRelativePathGet(relativePath : string): Promise<string> {
  return new Promise((resolve, reject) => {
    Files.filesRelativePathGet(relativePath)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function filesViewRelativePathGet(relativePath : string): Promise<string> {
  return new Promise((resolve, reject) => {
    Files.filesViewRelativePathGet(relativePath)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function filesPost(file: File): Promise<FilePath> {
  return new Promise((resolve, reject) => {
    Files.filesPost(file)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function filesWidthHeightRelativePathGet(relativePath : string, width: number, height: number): Promise<string> {
  return new Promise((resolve, reject) => {
    Files.filesWidthHeightRelativePathGet(relativePath, width, height)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}