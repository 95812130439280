import { BillingDaysListViewModel, PagedResultOfOrderPaymentViewModel } from "@/api-client";
import { BillingDays, Payments } from "@/network/api";
import { handleError } from "@/utils/auth";
import { Message } from "element-ui";

type Period = '_7Days' | '_3Months' | '_6Months' | '_12Months';

export function ordersIdPaymentGet(
  pgNum: number = 1,
  pgSize: number = 20,
  ordersId: string,
  period?: Period,
  ): Promise<PagedResultOfOrderPaymentViewModel> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Payments.apiOrdersOrdersIdPaymentGet(pgNum, pgSize, ordersId, period)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function ordersIdPaymentsExportGet(ordersId: string, period?: Period): Promise<number[]> {
  return new Promise((resolve, reject) => {
    Payments.apiOrdersOrdersIdPaymentsExportGet(ordersId, period, {
      responseType: "arraybuffer",
    })
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function paymentsManualPaymentImportPut(relativeFileUrl: string): Promise<string[]> {
  return new Promise((resolve, reject) => {
    Payments.apiPaymentsManualPaymentImportPut({relativeFileUrl: relativeFileUrl})
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Import successful!", showClose: true })
        resolve(res.data.value)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function billingDaysDdlGet(): Promise<BillingDaysListViewModel[]> {
  return new Promise((resolve, reject) => {
    BillingDays.apiBillingDayGet()
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

